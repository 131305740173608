const validMobile = (rule, value, callback) => {
  let reg = /^1[3-9](\d){9}$/;
  if (!value) {
    callback(new Error("请输入手机号码"));
  } else {
    if (!reg.test(value)) {
      callback(new Error("手机号码格式不对"));
    } else {
      callback();
    }
  }

};

const validLocation = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //仅支持输入数字、汉字，大小写字母，限制字符长度1~64个字符
    let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,200}$/;
    if (!reg.test(value)) {
      callback(new Error("格式不对"));
    } else {
      callback();
    }
  }
};

const validMAC = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    //支持输入大小写字母，数字，限制1~32位字符
    let reg = /^[A-z0-9]{1,32}$/;
    if (!reg.test(value)) {
      callback(new Error("MAC格式不对"));
    } else {
      callback();
    }
  }
};

const messageName = (rule, value, callback) => {
  let reg = /^.{1,24}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入1~24个字符"));
  } else {
    callback();
  }
};

const messageText = (rule, value, callback) => {
  let reg = /^.{2,64}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入2~64个字符"));
  } else {
    callback();
  }
};

const messageTitle = (rule, value, callback) => {
  let reg = /^.{1,12}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入1~12个字符"));
  } else {
    callback();
  }
};

const buttonText = (rule, value, callback) => {
  //支持输入汉字，2~8个字符
  let reg = /^[\u4E00-\u9FA5]{2,8}$/;
  if (!reg.test(value)) {
    callback(new Error("只能输入汉字，长度2~8位"));
  } else {
    callback();
  }
};

let linkVali = (rule, value, callback) => {
  let reg = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
  let reg1 = /^.{2,128}$/;
  if (reg.test(value)) {
    callback(new Error("只能包含大小写字母、数字、英文符号"));
  } else if (value && !reg1.test(value)) {
    callback('长度只能为2~128个字符');
  } else if (value == "" || value == undefined) {
    callback('请输入链接地址')
  } else {
    callback();
  }

};

const mobileReg = /^1[3-9](\d){9}$/;

export default {
  validMobile,
  validLocation,
  validMAC,
  mobileReg,
  messageName,
  messageText,
  buttonText,
  messageTitle,
  linkVali,
}