<template>
  <div class="lock-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog title="配置报警器" :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form :model="dialogList" :rules="rules" ref="ruleForm" label-width="162px">
        <el-form-item label="报警器MAC">
          <span v-text="dialogList.mac"></span>
        </el-form-item>
        <el-form-item label="应用场景">
          <el-select v-model="dialogList.scene" @change="onChangeSelect(dialogList.scene)"
            style="width: 320px;display: inline-block;">
            <el-option v-for="item in sceneList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接警电话" v-if="dialogList.scene == 'shop'" prop="phoneForShop">
          <el-input v-model="dialogList.phoneForShop" maxlength="11" placeholder="请输入接警电话"
            style="width: 320px;display: inline-block;"></el-input>
        </el-form-item>
        <el-form-item label="接警电话" v-if="dialogList.scene == 'school'" prop="phoneForSchool">
          <el-input v-model="dialogList.phoneForSchool" maxlength="11" placeholder="请输入接警电话"
            style="width: 320px;display: inline-block;"></el-input>
        </el-form-item>
        <div class="phoneTip">预设接警电话，当设备按下报警器红色紧急按键时，将拨打该售后服务电话，格式为11位手机号码。</div>
        <template v-if="dialogList.scene == 'school'">
          <el-form-item label="报警短信接收号码">
            <div class="phoneSmsClass" v-for="(item, index) in phoneSmsList" :key="index">
              <el-input v-model.trim="item.phoneSms" maxlength="11" placeholder="报警短信接收号码"
                style="width: 320px;display: inline-block;"></el-input>
              <div class="list-buts">
                <el-button class="list-but" type="primary" icon="el-icon-plus" @click="addItem(index)"></el-button>
                <el-button class="list-but" type="danger" icon="el-icon-minus" @click="delItem(index)"></el-button>
              </div>
            </div>
          </el-form-item>
          <div class="phoneTip">预设报警短信接收号码，当设备按下报警器红色紧急按键时，将发送短信给以上号码。格式为11位手机号码。</div>
          <div class="stateBox">
            <div class="stateTitle">是否开启web端弹框报警</div>
            <el-switch style="display: inline-block" v-model="dialogList.stateStr" active-color="#67C23A"
              inactive-color="#F56C6C" active-text="开启" inactive-text="关闭">
            </el-switch>
          </div>
        </template>
        <el-form-item label="安装位置" prop="location">
          <el-input type="textarea" class="textarea" v-model="dialogList.location" placeholder="请输入设备安装详细位置"
            maxlength="200" rows="4" show-word-limit style="width: 320px;display: inline-block;"></el-input>
        </el-form-item>
        <div style="text-align: center;">
          <!-- <el-button @click="onRester()">取消</el-button> -->
          <el-button type="primary" @click="onSubmit()">提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import ruleVerification from "@utils/ruleVerification.js"
  let validLocation = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback('请输入安装位置');
    } else {
      callback();
    }
  };

  let phoneForSchoolVali = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^1[3-9](\d){9}$/;
      if (!reg.test(value)) {
        callback(new Error("手机号码格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        sceneList: [{
            label: '商店',
            value: 'shop',
          },
          {
            label: '学校',
            value: 'school',
          }
        ],
        dialogList: {
          id: "",
          scene: 'shop',
          mac: "FFFFFFSDSSS",
          location: "",
          phoneSms: "",
          stateStr: true,
          phoneForShop: '',
          phoneForSchool: '',
        },
        phoneSmsList: [],
        phoneSmsTemplate: {
          phoneSms: "",
        },
        opt: {
          title: "报警器列表",
          search: [{
              key: "mac",
              label: "MAC",
              maxlength: 32,
              rules: [{
                validator: ruleVerification.validMAC,
                trigger: ['blur']
              }]
            },
            {
              key: "location",
              label: "安装位置",
              maxlength: 200,
            },
          ],
          columns: [{
              label: "MAC",
              key: "mac"
            },
            {
              label: "设备类型",
              key: "typeStr"
            },
            {
              label: "应用场景",
              key: "sceneStr"
            },
            {
              label: "安装位置",
              key: "location",
              opt: {
                isUserPopover: true
              }
            },
            {
              label: "最近一次使用时间",
              key: "lastAlarm"
            },
            {
              label: "电量",
              key: "batteryStr"
            },
            {
              label: "ICCID",
              key: "iccid"
            },
            {
              label: "操作",
              key: "action",
              type: "action-but",
              opt: {
                list: [{
                  label: "配置",
                  on(row) {
                    _this.setDetail(row);
                  }
                }, {
                  label: "详情",
                  on(row) {
                    _this.$router.push("/main/alarm/detail/" + row.mac);
                  }
                }]
              }
            }
          ],
        },
        rules: {
          phoneForShop: [{
            required: true,
            validator: ruleVerification.validMobile,
            trigger: ['change', 'blur']
          }],
          phoneForSchool: [{
            validator: phoneForSchoolVali,
            trigger: ['change', 'blur']
          }],
          location: [{
            required: true,
            validator: validLocation,
            trigger: ['change', 'blur']
          }],
        }
      };
    },
    created() {
      console.log("lock-list created!!");
    },
    activated() {
      console.log("lock-list activated!!");

    },
    deactivated() {
      this.dialogVisible = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        this.post("device-service/device/l2-alarm/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.batteryStr = item.battery ? item.battery + '%' : '--';
            item.typeStr = ['L2D报警器'][item.type - 1];
            if (item.scene == 'school') {
              item.sceneStr = '学校';
            } else if (item.scene == 'shop') {
              item.sceneStr = '商店';
            }

          }
          opt.cb(res.data);
        });
      },
      onChangeSelect(e) {
        // 初始化
        this.phoneSmsList = [{
          phoneSms: '',
        }]
        this.dialogList.stateStr = true;
      },
      addItem(idx) {
        if (this.phoneSmsList.length >= 5) {
          this.$message({
            showClose: true,
            message: "最多添加至5项",
            type: "warning"
          });
        } else {
          this.phoneSmsList.splice(
            idx + 1,
            0,
            JSON.parse(JSON.stringify(this.phoneSmsTemplate))
          );
        }
      },
      delItem(idx) {
        if (this.phoneSmsList.length == 1) {
          this.$message({
            showClose: true,
            message: "至少保留1项",
            type: "warning"
          });
        } else {
          this.phoneSmsList.splice(idx, 1);
        }
      },
      onSubmit() {
        this.$refs["ruleForm"].validate(valid => {
          if (valid) {
            for (let i = 0; i < this.phoneSmsList.length; i++) {
              let item = this.phoneSmsList[i];
              if (item.phoneSms && !ruleVerification.mobileReg.test(item.phoneSms)) {
                return this.$message({
                  message: '第' + (i + 1) + '条的报警短信接收号码格式不对！',
                  type: "warning"
                });
              }
            }
            let dto = {
              id: this.dialogList.id,
              mac: this.dialogList.mac,
              callPolicePhone: this.dialogList.scene == "school" ? this.dialogList.phoneForSchool : this
                .dialogList.phoneForShop,
              isOpenAlarm: this.dialogList.stateStr ? 1 : 2,
              location: this.dialogList.location,
              scene: this.dialogList.scene,
              receivePhone: [],
            }
            if (this.dialogList.scene == 'school') {
              if (this.phoneSmsList.length > 0) {
                this.phoneSmsList.forEach(item => {
                  if (item.phoneSms) {
                    dto.receivePhone.push(item.phoneSms)
                  }
                })
              }
            }
            this.post('device-service/device/l2-alarm/update', dto, {
              isUseResponse: true
            }).then(res => {
              if (res.data.code == 0) {
                this.dialogVisible = false;
                this.$message({
                  message: "配置报警器成功!",
                  type: "success"
                });
                this.$refs.myList.get();
              }
            })
          }
        })
      },
      setDetail(row) {
        this.dialogList = {
          id: "",
          scene: 'shop',
          mac: "",
          location: "",
          phoneSms: "",
          stateStr: true,
          phoneForShop: '',
          phoneForSchool: '',
        };
        this.dialogVisible = true;
        this.dialogList.id = row.id;
        this.phoneSmsTemplate = {
          phoneSms: "",
        };
        this.phoneSmsList = [{
          phoneSms: '',
        }];
        if (row.scene == "school") {
          this.dialogList.phoneForSchool = row.callPolicePhone;
          if (row.receivePhone) {
            this.phoneSmsList = [];
            let phoneList = row.receivePhone.split(',');
            if (phoneList.length > 0) {
              phoneList.forEach(item => {
                this.phoneSmsList.push({
                  phoneSms: item
                })
              })
            }
          }
        } else if (row.scene == "shop") {
          this.dialogList.phoneForShop = row.callPolicePhone;
        }
        this.dialogList.scene = row.scene ? row.scene : 'shop';
        this.dialogList.stateStr = row.isOpenAlarm == 1 ? true : false;
        this.dialogList.location = row.location;
        this.dialogList.mac = row.mac;
      },
    }
  };
</script>
<style lang="scss" scoped>
  .el-dialog {
    margin-top: 22vh !important;
    width: 720px;
  }

  .phoneTip {
    color: #c0c4cc;
    margin-left: 119px;
    position: relative;
    top: -9px;
  }

  .stateTitle {
    display: inline-block;
    // margin-left: 54px;
    margin-right: 10px;
  }

  .el-switch {
    line-height: 17px;
  }

  .stateBox {
    margin-bottom: 14px;
  }

  .list-buts {
    display: inline-block;
    margin-left: 14px;
  }

  .phoneSmsClass {
    margin-bottom: 10px;
  }
</style>